<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="参数配置名称">
              <el-input v-model="form.csMc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数配置代码">
              <el-input v-model="form.csDm"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="参数配置值">
              <el-input type="textarea" :rows="4" v-model="form.csz"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="参数配置描述">
              <el-input
                type="textarea"
                :rows="4"
                v-model="form.csms"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增参数配置",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      form: {
        csDm: "",
        csMc: "",
        csms: "",
        csz: "",
      },
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      this.form.imgUrl = res.data;
    },
    handleAvatarSuccess2(res) {
      this.form.hdzp = res.data;
    },
    async submitData() {
      let params = { ...this.form };
      params.createTime = new Date().getTime();
      let url = "";
      if (params.csId) {
        //修改
        url = "api/backstage/updateParamConfig";
      } else {
        url = "api/backstage/addParamConfig";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增参数配置";
      this.form = {};
    },
    getInfo(item) {
      this.visible = true;
      this.dialogTitle = "编辑参数配置";
      this.form = JSON.parse(JSON.stringify(item));
      this.form.csms = this.form.csMs;
      console.log(this.form);
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
